import React from "react"
import { graphql, Link } from "gatsby"
import Avatar from "react-avatar"
import sortedUniqBy from "lodash/sortedUniqBy"
import Layout from "../components/layout"
import { getSongPath, getTranslationPath } from "../utils/url"
import removeDiacritics from "../utils/removeDiacritics"
import SEO from "../components/seo"
import getTranslationRefTitle from "../utils/getTranslationRefTitle"
import marked from "marked"

export default ({ data }) => {
  const artist = data.strapiArtist

  const hymns = sortedUniqBy(
    [...artist.songs, ...artist.lyrics]
      .map(hymn => {
        return {
          ...hymn,
          plainTitle: removeDiacritics(hymn.title).trim(),
        }
      })
      .sort((a, b) => a.plainTitle.localeCompare(b.plainTitle)),
    hymn => hymn.plainTitle
  )

  const translations = artist.translations
    .map(translation => {
      return {
        ...translation,
        plainTitle: removeDiacritics(translation.title).trim(),
      }
    })
    .sort((a, b) => a.plainTitle.localeCompare(b.plainTitle))

  return (
    <Layout>
      <SEO title={artist.name} />
      <article>
        <ol className="breadcrumb">
          <li>
            <Link to="/artists">ΑΡΧΕΙΟ ΚΑΛΛΙΤΕΧΝΩΝ</Link>
          </li>
          <li>{removeDiacritics(artist.name).toUpperCase()}</li>
        </ol>
        <div className="flex items-center my-4">
          <Avatar
            name={artist.name}
            round
            size="120"
            textSizeRatio={4}
            src={artist.picture?.childImageSharp.fixed.src}
          />
          <h1 className="font-semibold ml-3 font-sans text-gray-900 text-3xl md:text-4xl leading-tight">
            {artist.name}
          </h1>
        </div>
        {artist.bio && (
          <div
            className="rtf text-gray-800 text-sm"
            dangerouslySetInnerHTML={{ __html: marked(artist.bio) }}
          />
        )}
        {artist.externalUrl && (
          <div className="my-2">
            <a
              href={artist.externalUrl}
              target="_blank"
              rel="noreferrer noopener"
              className="text-blue-800 hover:underline font-sans text-sm"
            >
              {artist.externalUrl}
            </a>
          </div>
        )}
        {hymns.length !== 0 && (
          <div className="my-5">
            <h2 className="font-semibold font-sans text-gray-900 mb-2 text-xl md:text-2xl">
              Πρωτότυποι Ύμνοι
            </h2>
            <ul className="list-disc list-inside">
              {hymns.map(song => {
                return (
                  <li key={song.id} className="my-2">
                    <Link
                      to={getSongPath(song)}
                      className="text-blue-700 hover:underline"
                    >
                      {song.title}
                      {song.pubyear && ` (${song.pubyear})`}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {translations.length !== 0 && (
          <div className="my-5">
            <h2 className="font-semibold font-sans text-gray-900 mb-2 text-xl md:text-2xl">
              Μεταφράσεις
            </h2>
            <ul className="list-disc list-inside">
              {translations.map(translation => {
                return (
                  <li key={translation.id} className="my-2">
                    <Link
                      to={getTranslationPath(translation)}
                      className="text-blue-700 hover:underline"
                    >
                      {getTranslationRefTitle(translation)}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiArtist(strapiId: { eq: $id }) {
      name
      bio
      externalUrl
      songs {
        id
        title: Title
        pubyear
      }
      lyrics {
        id
        title: Title
        pubyear
      }
      translations {
        title
        id
        pubyear
      }
      picture {
        childImageSharp {
          fixed(height: 120, width: 120, cropFocus: ATTENTION) {
            src
          }
        }
      }
    }
  }
`
