import sortedUniq from "lodash/sortedUniq"

function getTranslationRefTitle({ title, pubyear, song, translators = [] }) {
  const strBuf = [title]
  const attrs = [song && `«${song.title}»`, pubyear].filter(Boolean)

  if (attrs.length !== 0) {
    strBuf.push(`(${attrs.join(", ")})`)
  }

  const artists = sortedUniq(
    translators.map(artist => artist.name).sort((a, b) => a.localeCompare(b))
  )

  if (artists.length !== 0) {
    strBuf.push("-", artists.join(", "))
  }

  return strBuf.join(" ")
}

export default getTranslationRefTitle
